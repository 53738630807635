import { THEMES } from "../../core/consts";
import { generateForegroundColorObject } from "../utils";
import { commonColors as common } from "./common";
import { SemanticBaseColors, ThemeColors } from "./types";

const base: SemanticBaseColors = {
  light: {
    structure: {
      ...common.beige,
    },
    divider: {
      ...common.gray,
      DEFAULT: common.gray["800"],
    },
    focus: {
      ...common.blue,
      DEFAULT: common.blue[800],
    },
    base: {
      ...common.gray,
      foreground: generateForegroundColorObject(common.gray),
      DEFAULT: common.gray[300],
      disabled: common.gray[600],
    },
  },
};

const sharedThemeColors: Pick<
  ThemeColors,
  "success" | "warning" | "danger" | "default"
> = {
  default: {
    ...common.gray,
    DEFAULT: common.gray["700"],
    foreground: generateForegroundColorObject(common.gray),
  },
  success: {
    ...common.green,
    light: common.green[50],
    foreground: generateForegroundColorObject(common.green),
    DEFAULT: common.green[600],
    dark: common.green[800],
  },
  warning: {
    ...common.orange,
    light: common.orange[50],
    foreground: generateForegroundColorObject(common.orange),
    DEFAULT: common.orange[400],
    dark: common.orange[700],
  },
  danger: {
    ...common.red,
    light: common.red[100],
    foreground: generateForegroundColorObject(common.red),
    DEFAULT: common.red[700],
    dark: common.red[800],
  },
};

const themeRecare: DeepPartial<ThemeColors> = {
  ...base.light,
  ...sharedThemeColors,
  primary: {
    ...common.blue,
    light: common.blue[50],
    DEFAULT: common.blue[500],
    foreground: generateForegroundColorObject(common.blue),
    dark: common.blue[800],
  },
  secondary: {
    ...common.yellow,
    light: common.yellow[200],
    DEFAULT: common.yellow[300],
    foreground: generateForegroundColorObject(common.yellow),
    dark: common.yellow[400],
  },
  accent: {
    ...common.purple,
    light: common.purple[200],
    DEFAULT: common.purple[300],
    foreground: generateForegroundColorObject(common.purple),
    dark: common.purple[600],
  },
};

const themeBCP: DeepPartial<ThemeColors> = {
  ...base.light,
  ...sharedThemeColors,
  primary: {
    ...common.magenta,
    light: common.magenta[300],
    DEFAULT: common.magenta[500],
    foreground: generateForegroundColorObject(common.magenta),
    dark: common.magenta[800],
  },
  secondary: {},
  accent: {
    ...common.bcpAccent,
    light: common.bcpAccent[50],
    foreground: generateForegroundColorObject(common.bcpAccent),
    dark: common.bcpAccent[900],
  },
  ...sharedThemeColors,
};

const themeWeserEms: DeepPartial<ThemeColors> = {
  ...base.light,
  ...sharedThemeColors,
  primary: {
    ...common.steelBlue,
    light: common.steelBlue[200],
    DEFAULT: common.steelBlue[600],
    foreground: generateForegroundColorObject(common.steelBlue),
    dark: common.steelBlue[800],
  },
  secondary: {},
  accent: {},
  ...sharedThemeColors,
};

export const semanticColors = {
  [THEMES.WESER_EMS]: themeWeserEms,
  [THEMES.PROVIDER_SEARCH]: themeBCP,
  [THEMES.RECARE]: themeRecare,
};
